import { useEffect, useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import searchIcon from '../../assets/icons/InputIcon.svg';
import cancel from '../../assets/icons/new_icon/tag-cross.svg';
import { logout, setTokenofApp } from '../../redux/app/appActions';
import { RootState } from '../../redux/rootReducer';
import { sideBarData } from './sidebarData';
interface Props {
  setOpenSideBar: any;
}
function LoggedInNavbar({ setOpenSideBar }: Props) {
  let navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { userData, hasSeenTerms } = useSelector(
    (state: RootState) => state.mainAppData
  );
  const [searchText, setSearchText] = useState('');
  const [currentModule, setCurrentModule] = useState<any>(null);
  function handleSearch() {
    navigate(`/app/admin/search_shipments?search=${searchText}`);
  }
  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  useEffect(() => {
    const findModule = () => {
      for (const section of sideBarData) {
        for (const item of section.data) {
          if (location.pathname.startsWith(item.url)) {
            setCurrentModule(item);
            return;
          }
        }
      }
    };
    findModule();
  }, [location.pathname]);
  const renderSidebarLabel = () => {
    if (currentModule) {
      const pathMappings = [
        {
          condition: currentModule.admin === true,
          label: 'Admin' + currentModule.label,
        },
        {
          condition: location.pathname.startsWith(
            '/app/project/view_shipments'
          ),
          label: currentModule.label + ' ' + '- PO Summarized',
        },
        {
          condition: location.pathname.startsWith('/app/project/view'),
          label: currentModule.label + ' ' + 'View',
        },
        {
          condition: currentModule.label === 'Track',
          label: 'Tracking',
        },
      ];

      const matchedMapping = pathMappings.find((mapping) => mapping.condition);
      if (matchedMapping) {
        return matchedMapping.label;
      }
      // Default label
      return currentModule.label;
    }
    // If currentModule is not defined
    const fallbackMappings = [
      {
        condition: location.pathname.startsWith('/app/settings'),
        label: 'Profile Settings',
      },
      {
        condition: location.pathname.startsWith('/app/terms_agreed'),
        label: '',
      },
    ];

    const matchedFallback = fallbackMappings.find(
      (mapping) => mapping.condition
    );
    if (matchedFallback) {
      return matchedFallback.label;
    }
    return 'Dashboard';
  };
  const handleGoBack = () => {
    if (window.location.pathname !== '/app/dashboard') {
      navigate(-1); // This will navigate to the previous route
    }
  };
  const UserName = userData?.first_name + ' ' + userData?.last_name;

  return (
    <nav className='navbar p-0  shadow-sm mt-0'>
      <div className='container-fluid bg-white px-5' style={{ height: '74px' }}>
        <div className='col-12 col-md-4 my-auto d-flex align-items-center'>
          <p
            className='mb-0 navMainhead'
            onClick={() => {
              handleGoBack();
            }}
          >
            {!(
              location.pathname === '/app/dashboard' ||
              location.pathname === '/app/terms_agreed'
            ) ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='12'
                viewBox='0 0 22 12'
                className='me-2'
                fill='none'
              >
                <path
                  d='M0 6L10 11.7735V0.226497L0 6ZM22 5L9 5V7L22 7L22 5Z'
                  fill='#292D32'
                />
              </svg>
            ) : null}

            {renderSidebarLabel()}
          </p>
        </div>
        <div className='col-12 col-md-4'>
          <div className='input-group px-5'>
            <input
              // type='search'
              className='form-control ubbe-input nav-search icon-right-input'
              placeholder='Please enter bbe number, shipment id, or leg id'
              // aria-label='Search'
              // aria-describedby='button-addon2'
              id='search_shipment_legs'
              style={{
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
                fontSize: '14',
                fontWeight: '200',
                paddingRight: '45px',
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {searchText && (
              <span
                className='input-group-text searchIcon cursor_pointer'
                id='button-addon2'
                onClick={() => setSearchText('')}
              >
                <img src={cancel} alt={searchIcon} />
              </span>
            )}
            {!searchText && (
              <span
                className='input-group-text searchIcon'
                id='button-addon2'
                // onClick={handleSearch}
              >
                <img src={searchIcon} alt={searchIcon} />
              </span>
            )}
          </div>
        </div>

        <Navbar
          className='col-12 col-md-4 ms-auto justify-content-end'
          expand='sm'
          variant='light'
        >
          <Navbar.Toggle aria-controls='navbar-list-4'>
            <span
              className='navbar-toggler-icon'
              style={{ color: 'black' }}
            ></span>
          </Navbar.Toggle>
          <Navbar.Collapse id='navbar-list-4' style={{ flexGrow: 'unset' }}>
            <Nav className='ml-auto'>
              <NavDropdown
                title={UserName}
                id='navbarDropdownMenuLink'
                className='navbardropdown handle sideAvatarName'
                style={{ color: 'black', fontSize: '16px' }}
              >
                {(userData?.is_terms_agreed || hasSeenTerms) && (
                  <NavDropdown.Item
                    href={'/app/settings'}
                    className='pe-3 cursor_pointer navdroptext d-flex align-items-center gap-2'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19'
                      height='18'
                      viewBox='0 0 19 18'
                      fill='none'
                    >
                      <path
                        d='M15.1066 6.91495C13.7491 6.91495 13.1941 5.95495 13.8691 4.77745C14.2591 4.09495 14.0266 3.22495 13.3441 2.83495L12.0466 2.09245C11.4541 1.73995 10.6891 1.94995 10.3366 2.54245L10.2541 2.68495C9.57912 3.86245 8.46912 3.86245 7.78662 2.68495L7.70412 2.54245C7.36662 1.94995 6.60162 1.73995 6.00912 2.09245L4.71162 2.83495C4.02912 3.22495 3.79662 4.10245 4.18662 4.78495C4.86912 5.95495 4.31412 6.91495 2.95662 6.91495C2.17662 6.91495 1.53162 7.55245 1.53162 8.33995V9.65995C1.53162 10.44 2.16912 11.085 2.95662 11.085C4.31412 11.085 4.86912 12.045 4.18662 13.2225C3.79662 13.905 4.02912 14.775 4.71162 15.165L6.00912 15.9075C6.60162 16.26 7.36662 16.05 7.71912 15.4575L7.80162 15.315C8.47662 14.1375 9.58662 14.1375 10.2691 15.315L10.3516 15.4575C10.7041 16.05 11.4691 16.26 12.0616 15.9075L13.3591 15.165C14.0416 14.775 14.2741 13.8975 13.8841 13.2225C13.2016 12.045 13.7566 11.085 15.1141 11.085C15.8941 11.085 16.5391 10.4475 16.5391 9.65995V8.33995C16.5316 7.55995 15.8941 6.91495 15.1066 6.91495ZM9.03162 11.4375C7.68912 11.4375 6.59412 10.3425 6.59412 8.99995C6.59412 7.65745 7.68912 6.56245 9.03162 6.56245C10.3741 6.56245 11.4691 7.65745 11.4691 8.99995C11.4691 10.3425 10.3741 11.4375 9.03162 11.4375Z'
                        fill='#292D32'
                      />
                    </svg>
                    <p className='mb-0'> Profile Settings </p>
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item
                  className='pe-3 cursor_pointer navdroptext d-flex align-items-center gap-2'
                  onClick={() => {
                    const url = process.env.REACT_APP_HELP_URL;
                    if (url) {
                      window.open(url, '_blank');
                    }
                  }}
                >
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.4999 9.64501C16.4999 11.3625 15.6149 12.885 14.2499 13.845L13.2449 16.0575C13.0124 16.56 12.3374 16.6575 11.9849 16.23L10.8749 14.895C9.47991 14.895 8.19741 14.4225 7.22241 13.635L7.67241 13.1025C11.1374 12.84 13.8749 10.095 13.8749 6.75001C13.8749 6.18001 13.7924 5.61751 13.6424 5.07751C15.3449 5.97751 16.4999 7.68751 16.4999 9.64501Z'
                      fill='#292D32'
                    />
                    <path
                      d='M12.225 4.5525C11.3475 2.7525 9.39 1.5 7.125 1.5C4.02 1.5 1.5 3.8475 1.5 6.75C1.5 8.4675 2.385 9.99 3.75 10.95L4.755 13.1625C4.9875 13.665 5.6625 13.755 6.015 13.335L6.4275 12.84L7.125 12C10.23 12 12.75 9.6525 12.75 6.75C12.75 5.9625 12.5625 5.22 12.225 4.5525ZM9 7.3125H5.25C4.9425 7.3125 4.6875 7.0575 4.6875 6.75C4.6875 6.4425 4.9425 6.1875 5.25 6.1875H9C9.3075 6.1875 9.5625 6.4425 9.5625 6.75C9.5625 7.0575 9.3075 7.3125 9 7.3125Z'
                      fill='#292D32'
                    />
                  </svg>

                  <p className='mb-0'> Help</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className='pe-3 cursor_pointer navdroptext d-flex align-items-center gap-2'
                  onClick={() => {
                    const url = process.env.REACT_APP_TERMS_URL;
                    if (url) {
                      window.open(url, '_blank');
                    }
                  }}
                >
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.1425C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.1425C14.8725 16.5 16.5 14.8725 16.5 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM7.4775 11.175L5.79 12.8625C5.6775 12.975 5.535 13.0275 5.3925 13.0275C5.25 13.0275 5.1 12.975 4.995 12.8625L4.4325 12.3C4.2075 12.0825 4.2075 11.7225 4.4325 11.505C4.65 11.2875 5.0025 11.2875 5.2275 11.505L5.3925 11.67L6.6825 10.38C6.9 10.1625 7.2525 10.1625 7.4775 10.38C7.695 10.5975 7.695 10.9575 7.4775 11.175ZM7.4775 5.925L5.79 7.6125C5.6775 7.725 5.535 7.7775 5.3925 7.7775C5.25 7.7775 5.1 7.725 4.995 7.6125L4.4325 7.05C4.2075 6.8325 4.2075 6.4725 4.4325 6.255C4.65 6.0375 5.0025 6.0375 5.2275 6.255L5.3925 6.42L6.6825 5.13C6.9 4.9125 7.2525 4.9125 7.4775 5.13C7.695 5.3475 7.695 5.7075 7.4775 5.925ZM13.17 12.465H9.2325C8.925 12.465 8.67 12.21 8.67 11.9025C8.67 11.595 8.925 11.34 9.2325 11.34H13.17C13.485 11.34 13.7325 11.595 13.7325 11.9025C13.7325 12.21 13.485 12.465 13.17 12.465ZM13.17 7.215H9.2325C8.925 7.215 8.67 6.96 8.67 6.6525C8.67 6.345 8.925 6.09 9.2325 6.09H13.17C13.485 6.09 13.7325 6.345 13.7325 6.6525C13.7325 6.96 13.485 7.215 13.17 7.215Z'
                      fill='#292D32'
                    />
                  </svg>

                  <p className='mb-0'> Terms & Conditions </p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  // href={'/app/settings'}
                  className='pe-3 cursor_pointer navdroptext d-flex align-items-center gap-2'
                  onClick={() => {
                    const url = process.env.REACT_APP_PRIVACY_URL;
                    if (url) {
                      window.open(url, '_blank');
                    }
                  }}
                >
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.9051 3.12748L9.7801 1.58248C9.3526 1.42498 8.6551 1.42498 8.2276 1.58248L4.1026 3.12748C3.3076 3.42748 2.6626 4.35748 2.6626 5.20498V11.28C2.6626 11.8875 3.0601 12.69 3.5476 13.05L7.6726 16.1325C8.4001 16.68 9.5926 16.68 10.3201 16.1325L14.4451 13.05C14.9326 12.6825 15.3301 11.8875 15.3301 11.28V5.20498C15.3376 4.35748 14.6926 3.42748 13.9051 3.12748ZM9.5626 9.65248V11.625C9.5626 11.9325 9.3076 12.1875 9.0001 12.1875C8.6926 12.1875 8.4376 11.9325 8.4376 11.625V9.65248C7.6801 9.41248 7.1251 8.70748 7.1251 7.87498C7.1251 6.83998 7.9651 5.99998 9.0001 5.99998C10.0351 5.99998 10.8751 6.83998 10.8751 7.87498C10.8751 8.71498 10.3201 9.41248 9.5626 9.65248Z'
                      fill='#292D32'
                    />
                  </svg>

                  <p className='mb-0'>Privacy Policy </p>
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => {
                    window.scrollTo(0, 0);
                    localStorage.setItem('authToken', '');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('accessExpiry');
                    dispatch(setTokenofApp(''));
                    dispatch(logout());
                    navigate('/login');
                  }}
                  className='navdroptext  d-flex align-items-center gap-2'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='19'
                    height='18'
                    viewBox='0 0 19 18'
                    fill='none'
                  >
                    <path
                      d='M5.94228 9.05271C5.94228 8.74521 6.19728 8.49021 6.50478 8.49021H10.6148V2.14521C10.6073 1.78521 10.3223 1.50021 9.96228 1.50021C5.54478 1.50021 2.46228 4.58271 2.46228 9.00021C2.46228 13.4177 5.54478 16.5002 9.96228 16.5002C10.3148 16.5002 10.6073 16.2152 10.6073 15.8552V9.60771H6.50478C6.18978 9.61521 5.94228 9.36021 5.94228 9.05271Z'
                      fill='#292D32'
                    />
                    <path
                      d='M15.4369 8.65512L13.3069 6.51762C13.0894 6.30012 12.7294 6.30012 12.5119 6.51762C12.2944 6.73512 12.2944 7.09512 12.5119 7.31262L13.6819 8.48262H10.6069V9.60762H13.6744L12.5044 10.7776C12.2869 10.9951 12.2869 11.3551 12.5044 11.5726C12.6169 11.6851 12.7594 11.7376 12.9019 11.7376C13.0444 11.7376 13.1869 11.6851 13.2994 11.5726L15.4294 9.43512C15.6544 9.22512 15.6544 8.87262 15.4369 8.65512Z'
                      fill='#292D32'
                    />
                  </svg>{' '}
                  <p className='mb-0'> Logout </p>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </nav>
  );
}

export default LoggedInNavbar;
