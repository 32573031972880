import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './assets/styles/_colors.scss';
import './assets/styles/bootstrap.css';
import './assets/styles/custom.scss';
import './assets/styles/multi-select.css';
import './assets/styles/ubbe.scss';
import MainLoader from './components/global-components/loader';
import LoggedInNavbar from './components/navbar/loggedInNavbar';
import SideBar from './components/navbar/sideBar';
import { RootState } from './redux/rootReducer';
import { logout } from './redux/app/appActions';
import NewAlert from './components/global-components/newAlert';
const MainPage = lazy(() => import('./main'));

function App() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const expiredTime = localStorage.getItem('accessExpiry');
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  // const authToken = useSelector(
  //   (state: RootState) => state.mainAppData.userToken
  // );
  const { userData, userToken, hasSeenTerms } = useSelector(
    (state: RootState) => state.mainAppData
  );
  const isTermsAgreed = userData?.is_terms_agreed;
  const isVendor = userData?.is_vendor;
  let authenticatedUrls = location.pathname.includes('app/');
  let loginScreen = location.pathname === '/login';
  let resetPassword = location.pathname === '/reset_password';
  useEffect(() => {
    if (userToken === '' && (authenticatedUrls || location.pathname === '/')) {
      navigate('/login');
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (expiredTime && currentTimestamp > Number(expiredTime)) {
      localStorage.setItem('authToken', '');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessExpiry');
      dispatch(logout());
      navigate('/login');
      return;
    }
  }, [userToken, authenticatedUrls, navigate]);

  useEffect(() => {
    if (
      location.pathname === '/' &&
      userToken !== '' &&
      (isTermsAgreed || hasSeenTerms)
    ) {
      navigate('/app/home');
    }
    if (userToken !== '' && !(isTermsAgreed || hasSeenTerms)) {
      navigate('/app/terms_agreed');
    }
  }, []);

  return (
    <Suspense fallback={<MainLoader />}>
      {/* {!loginScreen && authenticatedUrls && <NewAlert />} */}
      {/* <div
        className={`d-flex flex-column ${
          !loginScreen && authenticatedUrls ? 'min-vh-90' : 'min-vh-100'
        }`}
      > */}
      <div className='d-flex flex-column min-vh-100'>
        <div
          className={`d-flex flex-column flex-grow-1 ${
            location.pathname === '/' ? 'landing' : !loginScreen ? 'app-bg' : ''
          }`}
        >
          {!authenticatedUrls && !loginScreen && !resetPassword ? (
            <></>
          ) : !loginScreen && !resetPassword ? (
            <LoggedInNavbar setOpenSideBar={setOpenSideBar} />
          ) : null}
          {authenticatedUrls && (isTermsAgreed || hasSeenTerms) && (
            <SideBar setOpenSideBar={setOpenSideBar} />
          )}
          <div className={authenticatedUrls ? 'main-page' : ''}>
            <MainPage />
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
